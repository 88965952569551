<template>
    <div class="mainwrap ipaccess" v-loading="loading">
        <div class="title">
            IP白名單
            <div class="addbtn" v-if="permissionType === 2">
                <el-button type="success" @click="toAddIp">新增</el-button>
            </div>
        </div>

        <div class="tablewrap">
            <div class="tablewrap">
            <el-table :data="tableData" style="width: 100%" stripe>
                <el-table-column prop="id" label="ID"> </el-table-column>
                <el-table-column prop="iPv4Address" label="IP地址"> </el-table-column>
                <el-table-column prop="title" label="備注"> </el-table-column>
                <el-table-column label="操作" width="220" v-if="permissionType === 2">
                <template slot-scope="scope">
                    <el-button @click="handleEdit(scope.row)" type="primary" plain>
                    编辑
                    </el-button>
                        <div class="delbtn">
                            <el-popconfirm
                            confirm-button-text='確定'
                            cancel-button-text='取消'
                            confirm-button-type="danger"
                            icon="el-icon-info"
                            icon-color="red"
                            title="確定刪除？"
                            @confirm="delIP(scope.row.id)"
                            >
                                <el-button type="danger" slot="reference">刪除</el-button>
                            </el-popconfirm>
                        </div>
                </template>
                </el-table-column>
            </el-table>
            </div>

        </div>

        <div class="pagination">
            <el-pagination
                background
                :hide-on-single-page="isSinglePage"
                layout="prev, pager, next,jumper"
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page="currentPage"
                :total="tableDataTotal"
            >
            </el-pagination>
        </div>

        <el-dialog title="添加白名單" width="500px" :visible.sync="dialogFormVisible">
            <el-form
                :model="ipForm"
                :rules="rules"
                ref="ipForm"
                label-width="90px"
                label-position="left">
                <el-form-item label="IP地址：" prop="iPv4Address">
                    <el-input v-model="ipForm.iPv4Address"></el-input>
                </el-form-item>
                <el-form-item label="備注：" prop="title">
                    <el-input v-model="ipForm.title"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addIp">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as api from "./api";
import { getStore} from '@/utils/storage';
export default {
  name: 'ipAccess',
    data(){
        return{
          loading:false,
          tableData:[],
          isSinglePage:false,
          pageSize:10,
          currentPage:1,
          tableDataTotal:0,
          dialogFormVisible:false,
          ipForm:{
            id:"",
            title:"",
            iPv4Address:""
          },
          rules:{
            title: [{ required: true, message: "請輸入備注", trigger: "blur" }],
            iPv4Address: [{ required: true, message: "請輸入IP地址", trigger: "blur" }],
          },
          permissionType: -1
        }
    },
    created(){
      this.getIpAccess();
      this.permissionType = parseInt(getStore("permissionType"));
    },
    methods:{
        // 新增
      toAddIp(){
        this.ipForm={
          id:"",
          title:"",
          iPv4Address:""
        },
        this.dialogFormVisible=true;
      },
      // 刪除
      delIP(id){
        api.deleteIpAccess(id).then(res=>{
          if(res.systemCode===200){
            this.$message.success('刪除成功');
            if (this.tableData.length <= 1) {
                this.currentPage = this.currentPage > 1 ? this.currentPage - 1 : 1;
            }
            this.getIpAccess();
          }
        })
      },
      // 編輯
      handleEdit(item){
          this.ipForm=item;
          this.dialogFormVisible=true;
      },
      // 確定新增
      addIp(){
        this.$refs["ipForm"].validate(valid => {
          if (valid) {
              let params={
                  title:this.ipForm.title,
                  iPv4Address:this.ipForm.iPv4Address
              }
              if(this.ipForm.id){
                  params.id=this.ipForm.id;
              }
              api.ipAccessEdit(params).then(res=>{
                  if(res.systemCode===200){
                      this.$message.success("提交成功");
                      this.dialogFormVisible=false;
                      this.getIpAccess();
                  }
              })
          }else {
              console.log("error submit!!");
              return false;
          }
        });

      },
      // 獲取列表
      getIpAccess(){
        this.loading=true;
        api.getIpAccessList({
            SkipCount:(this.currentPage-1)*this.pageSize,
            MaxResultCount:this.pageSize
        }).then(res=>{
            if(res.systemCode===200){
                this.tableData=res.data.items;
                this.tableDataTotal=res.data.totalCount;
            }
            this.loading=false;
        })
      },
      // 翻頁
      handleCurrentChange(val){
          this.currentPage=val;
          this.getIpAccess();
      }
    }
}
</script>

<style lang="scss" scoped>
.ipaccess{
    .addbtn {
        width: 100px;
        float: right;
    }
    .delbtn{
        display: inline-block;
        margin-left: 10px;
    }
}
</style>
