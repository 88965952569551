import { getRequest,postRequest,postFormReq,deleteRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

// 獲取列表
export const getIpAccessList=(params)=>{
    return getRequest(API.ipAccess,params);
}
// 新增
export const ipAccessEdit=(params)=>{
    return postRequest(API.ipAccessEdit,params);
}
// 刪除
export const deleteIpAccess=(params)=>{
    return deleteRequest(API.ipAccess+'/'+params);
}